import React from "react"

const Me = () => (
  <div>
    <h1>This is me, do you need to learn more?!</h1>
    <p>Here we go</p>
  </div>
)

export default Me
